/** @jsxImportSource @emotion/react */
import React from "react";
import {css} from "@emotion/react";
import reactCreateClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Tooltip from "react-tooltip";
import {IconButton as MuiIconButton, Button as MuiButton} from "@mui/material";

import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

// Make functional
const TextButton = reactCreateClass({
  displayName: "TextButton",

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      type: "default",
      disabled: false,
      iconStyle: {},
      labelStyle: {},
      style: {}
    };
  },

  render() {
    const fullTheme = this.context.theme;
    const {
      icon,
      suffixIcon,
      link,
      type,
      disabled,
      label,
      onClick,
      style,
      labelStyle,
      iconStyle,
      suffixIconStyle,
      containerStyle,
      id,
      fullWidth,
      extraStyle,
      tooltipContent,
      iconType,
      stackedIcon,
      className,
      testId,
      dataProductId,
    } = this.props;

    const iconEl = iconType === "bhi" ? <i
        className={`bhi-${icon}`}
        style={{
          ...iconStyle,
          marginRight: stackedIcon && label === "" ? "-8px" : label === "" ? 0 : 8
        }} /> : <i
        className={`fa fa-${icon}`}
        style={{
          ...iconStyle,
          marginRight: stackedIcon && label === "" ? "-8px" : label === "" ? 0 : 8
        }} />;

    const stackedIconEl = iconType === "bhi" ? <i
        className={`bhi-${stackedIcon}`}
        style={{
          ...stackedIconStyle(fullTheme),
          marginLeft: stackedIcon && label === "" ? 4 : "-1.5em",
          marginRight: stackedIcon && label === "" ? -8 : "0.5em"
        }} /> : <i
        className={`fa fa-${stackedIcon}`}
        style={{
          ...stackedIconStyle(fullTheme),
          marginLeft: stackedIcon && label === "" ? 4 : "-1.5em",
          marginRight: stackedIcon && label === "" ? -8 : "0.5em"
        }} />;

    const suffixIconEl = iconType === "bhi" ? <i
        className={`bhi-${suffixIcon}`}
        style={{right: 10, position: "absolute", ...suffixIconStyle}} /> : <i
        className={`fa fa-${suffixIcon}`}
        style={{right: 10, position: "absolute", ...suffixIconStyle}} />;
    const finalLabelStyle = icon ? labelStyle : {paddingRight: 0, paddingLeft: 0, whiteSpace: "nowrap", ...labelStyle};
    const disabledTextButtonStyle = disabled ? disabledButtonStyle : {};
    const override = extraStyle ? extraStyle : {};
    const bgColor = this.props.backgroundColor ? {backgroundColor: this.props.backgroundColor} : {};


    const finalStyle = {
      // theme notes, buttons will need a rethink so that the button color is pulled in from the theme rather than
      // inserted in textButtonStyles(this.props.theme).type //theme notes this is a problem because how does hover
      // state get decided
      ...baseFlatButtonStyle,
      ...textButtonStyles(fullTheme)[type],
      ...style,
      ...disabledTextButtonStyle,
      ...override,
      ...bgColor
    };

    return (
        <div
            data-tip={tooltipContent}
            style={{display: "inline", width: fullWidth ? "100%" : "auto", ...containerStyle}}>
          <MuiButton
              data-product-id={dataProductId}
              data-test-id={testId}
              className={className}
              id={id}
              href={link}
              style={{...finalStyle}}
              fullWidth={fullWidth}
              disabled={disabled}
              onClick={onClick}>
            {icon && iconEl}
            {stackedIcon && stackedIconEl}
            <span style={{...finalLabelStyle, paddingRight: suffixIcon ? 20 : 0}}>{label}</span>
            {suffixIcon && suffixIconEl}
          </MuiButton>
          <Tooltip place="top" type="light" effect="solid" />
        </div>
    );
  }
});
TextButton.contextType = CustomThemeContext;

const IconButton = reactCreateClass({
  displayName: "IconButton",

  mixins: [PureRenderMixin],

  getDefaultProps() {
    return {
      type: "bright",
      hoverType: "bright",
      isDragHandle: false
    };
  },

  render() {
    const fullTheme = this.context.theme;
    const {
      testId,
      type,
      hoverType,
      icon,
      label,
      labelStyle,
      labelClassName,
      onClick,
      disableClick,
      customStyle,
      isDragHandle,
      id,
      style,
      iconStyle,
      size,
      iconType,
      container
    } = this.props;

    const disabledIconButtonStyle = disableClick ? disabledButtonStyle : {};
    const dragHandleStyle = isDragHandle ? {cursor: "move"} : {};
    const colorOverride = type === "alert" ? {color: fullTheme.palette.error.main} : {};
    const newStyle = {
      backgroundColor: "transparent",
      boxShadow: "none",
      ...baseIconButtonStyle,
      ...customStyle,
      ...iconStyle,
      ...dragHandleStyle,
      ...disabledIconButtonStyle,
      ...style,
      ...colorOverride
    };
    const dragHandleClass = isDragHandle ? "drag-handle" : "";
    return (
        <div
            style={{
              display: "inline",
              pointerEvents: disableClick ? "none" : "auto",
              opacity: disableClick && !isDragHandle ? 0.5 : 1
            }}>
          <MuiIconButton
              data-test-id={testId}
              id={id}
              style={newStyle}
              onClick={onClick}
              disableRipple
              disableFocusRipple
              size={size}
              css={css`
                &:hover {
                  color: ${iconButtonHoverColorByHoverType(fullTheme)[hoverType]} !important;
                }`}>
            <div
                style={{
                  display: "flex",
                  flexDirection: container === "column" ? "column" : "row",
                  alignItems: "center"
                }}>
              {iconType === "bhi"
                  ? <i className={`bhi-${icon} ${dragHandleClass}`} />
                  : <i className={`fa fa-${icon} ${dragHandleClass}`} />
              }
              {label &&
                  <div
                      style={{
                        fontFamily: fullTheme.typography.fontFamily,
                        fontSize: "0.7rem",
                        paddingTop: 4,
                        ...labelStyle
                      }}
                      className={`${labelClassName} ${dragHandleClass}`}>
                    {label}
                  </div>}
            </div>
          </MuiIconButton>
        </div>);
  }
});
IconButton.contextType = CustomThemeContext;

const stackedIconStyle = theme => ({
  borderRadius: "50%",
  color: theme.palette.text.main,
  backgroundColor: theme.palette.background.paper,
  fontSize: "0.6em",
  padding: "0.2em 0.4em",
  marginBottom: "-1em",
  marginRight: "0.5em"
});

const disabledButtonStyle = {
  pointerEvents: "none"
};

const baseFlatButtonStyle = {
  cursor: "pointer",
  paddingRight: "1.5rem",
  paddingLeft: "1.5rem",
  lineHeight: 1.8,
  height: 36
};

const textButtonStyles = (theme) => ({
  default: {
    borderRadius: "3px",
    border: "none",
    color: theme.palette.text.main,
    backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : theme.palette.background.card
  },

  inverted: {
    borderRadius: "3px",
    border: "none",
    color: theme.palette.text.main,
    backgroundColor: theme.palette.background.paper
  },

  primary: {
    borderRadius: "3px",
    border: "none",
    color: theme.palette.text.inverted,
    backgroundColor: theme.palette.button.primary
  },

  secondary: {
    boxShadow: "none"
  },

  success: {
    borderRadius: "3px",
    border: "none",
    color: "#fff",
    backgroundColor: theme.palette.button.success
  },

  alert: {
    borderRadius: "3px",
    border: "none",
    color: "#fff",
    backgroundColor: theme.palette.button.alert
  },

  alertInner: {
    borderRadius: "3px",
    border: `1px solid ${theme.palette.button.alert}`,
    color: theme.palette.button.alert,
    backgroundColor: theme.palette.background.card
  },

  defaultInner: {
    borderRadius: "3px",
    border: `1px solid ${theme.themeId === "light" ? theme.palette.primary.main : theme.palette.text.main}`,
    color: theme.themeId === "light" ? theme.palette.primary.main : theme.palette.text.main,
    backgroundColor: theme.palette.background.card
  },

  dark: {
    borderRadius: "3px",
    border: "none",
    color: "#fff",
    backgroundColor: theme.palette.button.dark
  }
});

const baseIconButtonStyle = {
  borderRadius: "3px",
  cursor: "pointer",
  position: "relative",
  verticalAlign: "middle",
  zIndex: 1,
  fontWeight: 300,
  lineHeight: 1,
  fontSize: "inherit",
  textAlign: "center",
  padding: "8px 10px",
  textTransform: "none"
};

const iconButtonHoverColorByHoverType = (theme) => ({
  bright: theme.themeId === "light" ? "#666" : "#eaeaea",
  dark: "#555555",
  alert: "#ce4d4d",
  success: "green"
});

export {
  IconButton,
  TextButton
};
