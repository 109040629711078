/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";
import Immutable from "immutable";
import DOMPurify from "dompurify";
import moment from "moment";
import {TextField} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faExclamationTriangle} from "@fortawesome/pro-regular-svg-icons";
import { CustomThemeContext } from "js/common/themes/CustomThemeProvider";
import {jsx, css} from "@emotion/react";

import AdminHeader from "js/admin/common/admin-header";
import LoadingSpinner from "js/common/views/loading-spinner";
import InteractiveTable from "js/common/views/tables/interactive-table";
import Dialog from "js/common/views/tabs-dialog";
import pure from "js/common/views/pure";
import currentClient from "js/common/repo/backbone/current-client";
import {IconButton} from "js/common/views/inputs/buttons";
import * as Ajax from "js/common/ajax";
import * as Popups from "js/common/popups";
import * as Users from "js/common/users";
import * as Colors from "js/common/cube19-colors";
import * as CubeTV from "js/common/cubetv";
import * as Branding from "js/common/branding-constants"

const DealFlashPage = createReactClass({

  getInitialState() {
    return {
      dealFlashes: Immutable.List(),
      loadingFlashHistory: false,
      showMoreInfo: false,
      currentDealFlashId: null,
      channels: null,
      cube19PlacementIdToIsReflashing: Immutable.Map(),
      searchTerm: ""
    };
  },

  componentDidMount() {
    this.loadAndSetFlashHistory();
    CubeTV.loadChannels().then(channels => this.setState({channels}));
  },

  render() {
    const {
      loadingFlashHistory,
      showMoreInfo,
      dealFlashes,
      searchTerm,
      channels,
      currentDealFlashId,
      cube19PlacementIdToIsReflashing
    } = this.state;

    const { theme } = this.props;
    const currentUser = Users.getCurrentUser();
    const isCube19User = Users.isCube19User(currentUser);
    return (
        <div>
          {isCube19User && <ReflashById
              cube19PlacementIdToIsReflashing={cube19PlacementIdToIsReflashing}
              onReflashPressed={this.reflashAndUpdateUi} />}
            <AdminHeader>
              Deal Flashes (Last 7 days)
            </AdminHeader>
          {loadingFlashHistory
              ? <LoadingSpinner />
              : <DealFlashTable
                  dealFlashes={dealFlashes}
                  searchTerm={searchTerm}
                  cube19PlacementIdToIsReflashing={cube19PlacementIdToIsReflashing}
                  onShowMoreInfo={this.showMoreInfoForDealFlash}
                  onReflashPressed={this.reflashAndUpdateUi}
                  onSearchChange={searchTerm => this.setState({searchTerm})} />}
          {showMoreInfo &&
          <Dialog
              label={<span>Deal Flash Details</span>}
              width={"75vw"}
              height={"75vh"}
              content={<MoreDealFlashInfo
                  theme={theme}
                  dealFlash={dealFlashes.find(x => x.get("parentId") === currentDealFlashId)}
                  channels={channels} />}
              onRequestClose={() => this.setState({showMoreInfo: false})} />}
        </div>);
  },

  reflashAndUpdateUi(cube19PlacementId) {
    this.setState(state => {
      const oldValue = state.cube19PlacementIdToIsReflashing;
      return {cube19PlacementIdToIsReflashing: oldValue.set(cube19PlacementId, true)};
    });

    reflashPlacement(cube19PlacementId)
        .then(res => {
          this.setState(state => {
            const oldValue = state.cube19PlacementIdToIsReflashing;
            return {cube19PlacementIdToIsReflashing: oldValue.set(cube19PlacementId, false)};
          });

          if (res.get("sent")) {
            Popups.success("Deal successfully reflashed.");
          } else {
            let warning;
            switch (res.get("notFlashedReason")) {
              case "DEAL_FLASH_PERMISSION":
                warning = "You do not have the required permissions for deal flashes.<br />Please contact support.";
                break;
              case "CONTRACT_EXTENSION_PERMISSION":
                warning = "This deal is a contract extension, deal flashes for these are disabled for your company.";
                break;
              case "NO_SUCH_PLACEMENT":
                warning = "We were unable to find this deal.";
                break;
              default:
                warning = "We were unable to reflash that deal. Please contact support.";
            }
            Popups.warning(warning);
          }
        })
        .catch(e => {
          this.setState(state => {
            const oldValue = state.cube19PlacementIdToIsReflashing;
            return {cube19PlacementIdToIsReflashing: oldValue.set(cube19PlacementId, false)};
          });
          console.error(e);
          Popups.contactSupport();
        });
  },

  showMoreInfoForDealFlash(dealFlash) {
    this.setState({
      showMoreInfo: true,
      currentDealFlashId: dealFlash.get("parentId")
    });
  },

  setDealFlashAnimationFlag(flag) {
    // todo disable checkbox whilst updating this in backend
    currentClient.set("showDealFlashAnimation", flag);
    currentClient.save().then(() => this.forceUpdate());
  },

  async loadAndSetFlashHistory() {
    this.setState({loadingFlashHistory: true});

    try {
      // NOTE we remove all events without a parentId (new property), this is required for deal flash ui
      // we also remove all fake deal flashes, since these are done with real placements it would confuse users
      const events = (await loadFlashHistory())
          .filter(e => e.getIn(["payload", "parentId"])
              && !e.getIn(["payload", "isFake"]));

      const placementIds = events
          .filter(event => event.get("category") === "deal_flash:sent")
          .map(event => event.getIn(["payload", "placementId"]))
          .toSet();

      const placementNames = await loadPlacementNames(placementIds);
      const idToPlacementName = placementNames.groupBy(p => p.get("id")).map(ps => ps.first());

      const dealFlashes = events
          .groupBy(a => a.getIn(["payload", "parentId"]))
          .entrySeq()
          .filter(([_, eventsForFlash]) => eventsForFlash.some(e => e.get("category") === "deal_flash:sent"))
          .map(([parentId, eventsForFlash]) => {
            const deviceIdAndCategoryToEvents = eventsForFlash
                .groupBy(e => e.getIn(["payload", "cube19DeviceId"]))
                .mapEntries(e => [
                  e[0],
                  e[1].groupBy(e => e.get("category"))])
                .entrySeq();

            const placementId = eventsForFlash
                .find(e => e.get("category") === "deal_flash:sent")
                .getIn(["payload", "placementId"]);
            const placement = idToPlacementName.get(placementId);
            return Immutable.fromJS({
              parentId,
              placement,
              placementId,
              deviceIdAndCategoryToEvents
            });
          });
      this.setState({dealFlashes});
    } catch (e) {
      console.error(e);
      Popups.contactSupport();
    }
    this.setState({loadingFlashHistory: false});
  }

});

const ReflashById = createReactClass({
  mixins: [PureRenderMixin],

  getInitialState() {
    return {
      placementId: ""
    };
  },

  render() {
    const {
      cube19PlacementIdToIsReflashing,
      onReflashPressed
    } = this.props;
    const placementId = this.state.placementId;

    return (
        <div>
          <AdminHeader>{Branding.brandingName} admin: Reflash deal by ID</AdminHeader>
          <div style={{padding: "0.5rem"}}>
            <TextField variant="standard"
                style={{marginTop: 15, width: 256}}
                label={`${Branding.brandingName} Placement ID`}
                value={this.state.placementId}
                onChange={e => this.setState({placementId: e.target.value})} />
            <IconButton
                testId="reflash-by-id-button"
                label="Reflash"
                icon="redo"
                customStyle={{marginTop: 24}}
                container="column"
                disableClick={!placementId || cube19PlacementIdToIsReflashing.get(placementId)}
                onClick={() => onReflashPressed(placementId)}
                size="large" />
          </div>
        </div>
    );
  }

});

const DealFlashTable = pure(({
  dealFlashes,
  searchTerm,
  cube19PlacementIdToIsReflashing,
  onShowMoreInfo,
  onReflashPressed,
  onSearchChange
}) => {
  const columns = [
    {label: "Placement ID", foundationClasses: "medium-1"},
    {label: "Date/Time", foundationClasses: "medium-2"},
    {label: "Owner", foundationClasses: "medium-2"},
    {label: "Candidate", foundationClasses: "medium-2"},
    {label: "Job", foundationClasses: "medium-2"},
    {label: "Additional", foundationClasses: "medium-1"},
    {label: "Actions", foundationClasses: "medium-2"}]
  ;
  const rows = dealFlashes
      .filter(dealFlash => dealFlashMatchesSearchTerm(dealFlash, searchTerm))
      .sort((dealFlashA, dealFlashB) => {
        const timestampA = dealFlashA
            .get("deviceIdAndCategoryToEvents")
            .find(([deviceId, _]) => deviceId === undefined)[1]
            .get("deal_flash:sent")
            .first()
            .get("timestamp");
        const timestampB = dealFlashB
            .get("deviceIdAndCategoryToEvents")
            .find(([deviceId, _]) => deviceId === undefined)[1]
            .get("deal_flash:sent")
            .first()
            .get("timestamp");
        return moment(timestampB).diff(moment(timestampA));
      })
      .map(dealFlash => {
        return generateDealFlashRow(
            dealFlash,
            cube19PlacementIdToIsReflashing,
            onShowMoreInfo,
            onReflashPressed);
      })
      .toArray();
  return (
      <div style={{margin: "0.5rem"}}>
        <TextField variant="standard"
            id={"deal-flash-table-search"}
            label={"Search"}
            style={{marginTop: 15, marginBottom: "0.5rem", width: 256}}
            onChange={event => onSearchChange(event.target.value)}
            value={searchTerm} />
        <InteractiveTable columns={columns} rows={rows} />
      </div>);
}, "DealFlashTable");

const MoreDealFlashInfo = pure(({theme, dealFlash, channels}) => {
  const receivedEvents = dealFlash
      .get("deviceIdAndCategoryToEvents")
      .filter(deviceIdAndCategoryToEvents => deviceIdAndCategoryToEvents[1].has("deal_flash:received"));
  return (
      <div>
        {receivedEvents.isEmpty() && <span>No devices received this deal flash.</span>}
        {receivedEvents
            .map(deviceIdAndCategoryToEvents => {
              const detailsClass = css({
                backgroundColor: theme.themeId === "light" ? theme.palette.background.paper : Colors.greyDark,
                borderRadius: 5,
                padding: "1rem",
                marginBottom: "1rem"
              });
              const categoryToEvents = deviceIdAndCategoryToEvents[1];
              const receivedEvent = categoryToEvents.get("deal_flash:received").first();

              return (
                  <div css={detailsClass} key={receivedEvent.get("id")}>
                    <IdentifyInfo receivedEvent={receivedEvent} channels={channels} theme={theme} />
                    <CompletionInfo categoryToEvents={categoryToEvents} theme={theme} />
                  </div>);
            })
        }
      </div>);
}, "MoreDealFlashInfo");

const IdentifyInfo = pure(({receivedEvent, channels, theme}) => {
  const payload = receivedEvent.get("payload");
  const cleanDeviceId = DOMPurify.sanitize(payload.get("cube19DeviceId"));
  const cleanIp = DOMPurify.sanitize(payload.get("ipAddress"));
  const cleanOsName = DOMPurify.sanitize(payload.get("osName"));
  const cleanOsVersion = DOMPurify.sanitize(payload.get("osVersion"));
  const cleanBrowserName = DOMPurify.sanitize(payload.get("browserName"));
  const cleanBrowserVersion = DOMPurify.sanitize(payload.get("browserVersion"));
  const os = `${cleanOsName} ${cleanOsVersion}`;
  const browser = `${cleanBrowserName} ${cleanBrowserVersion}`;
  const username = Users.getUser(payload.get("currentUserId")).get("username");
  const receivedAt = moment(payload.get("timestamp")).format("lll");
  const channel = channels.find(channel => channel.get("id") === payload.get("channelId"));


  return (
      <div css={css({display: "flex", flexWrap: "wrap"})}>
        <div css={moreInfoContainerStyle}>
          <span css={moreInfoHeaderStyle(theme)}>Device ID</span>
          <span css={moreInfoDetailStyle}>{cleanDeviceId}</span>
        </div>
        <div css={moreInfoContainerStyle}>
          <span css={moreInfoHeaderStyle(theme)}>IP Address</span>
          <span css={moreInfoDetailStyle}>{cleanIp}</span>
        </div>
        <div css={moreInfoContainerStyle}>
          <span css={moreInfoHeaderStyle(theme)}>Operating System</span>
          <span css={moreInfoDetailStyle}>{os}</span>
        </div>
        <div css={moreInfoContainerStyle}>
          <span css={moreInfoHeaderStyle(theme)}>Browser</span>
          <span css={moreInfoDetailStyle}>{browser}</span>
        </div>
        <div css={moreInfoContainerStyle}>
          <span css={moreInfoHeaderStyle(theme)}>Gamification User</span>
          <span css={moreInfoDetailStyle}>{username}</span>
        </div>
        {channel &&
        <div css={moreInfoContainerStyle}>
          <span css={moreInfoHeaderStyle(theme)}>Gamification Channel</span>
          <span css={moreInfoDetailStyle}>{channel.get("name")}</span>
        </div>}
        <div css={moreInfoContainerStyle}>
          <span css={moreInfoHeaderStyle(theme)}>Received At</span>
          <span css={moreInfoDetailStyle}>{receivedAt}</span>
        </div>
      </div>);
});

const CompletionInfo = pure(({categoryToEvents, theme}) => {
  const topContainerStyle = css({display: "flex", flexWrap: "wrap"});
  const warningContainerStyle = css({
    marginTop: "auto",
    marginBottom: "auto",
    marginLeft: "1rem",
    marginRight: "1rem"
  });
  const receivedEvents = categoryToEvents.get("deal_flash:received");
  // TODO remove code for payload without users after suitable transition
  const flashDetails = receivedEvents
      .flatMap(receivedEvent => {
        const payload = receivedEvent.get("payload");
        if (payload.has("users")) {
          return payload.get("users").map(user => user.merge(payload.delete("users")));
        } else {
          return Immutable.List.of(payload);
        }
      })
      .map(userPayload => {
        if (!userPayload.has("userId")) {
          const message = "Gamification was unable to display this deal flash.";
          return (
              <div>
                <hr />
                <div css={topContainerStyle}>
                  <div css={warningContainerStyle}>
                    <FontAwesomeIcon icon={faExclamationTriangle} color={"red"} />
                    <span>&nbsp;{message}</span>
                  </div>
                </div>
              </div>);

        } else {
          const userId = userPayload.get("userId");
          const ownerName = Users.getUser(userId).get("fullName");
          const flashId = userPayload.get("flashId");
          const startEvents = categoryToEvents.get("deal_flash:start");
          const startEvent = startEvents ? startEvents.find(e => e.getIn(["payload", "flashId"]) === flashId) : null;
          const endEvents = categoryToEvents.get("deal_flash:end");
          const endEvent = endEvents ? endEvents.find(e => e.getIn(["payload", "flashId"]) === flashId) : null;
          const dataLoadedEvents = categoryToEvents.get("deal_flash:data_loaded");
          const dataLoadedEvent = dataLoadedEvents ? dataLoadedEvents.find(e => e.getIn(["payload", "flashId"])
              === flashId) : null;
          const allowedToFlashForUser = userPayload.get("allowedToFlashForUser");

          let content;
          if (startEvent || endEvent) {
            content =
                <div css={topContainerStyle}>
                  <div css={moreInfoContainerStyle}>
                    <span css={moreInfoHeaderStyle(theme)}>Owner</span>
                    <span css={moreInfoDetailStyle}>{ownerName}</span>
                  </div>
                  {startEvent &&
                  <div css={moreInfoContainerStyle}>
                    <span css={moreInfoHeaderStyle(theme)}>Start Timestamp</span>
                    <span css={moreInfoDetailStyle}>
              {moment(startEvent.getIn(["payload", "timestamp"])).format("lll")}
            </span>
                  </div>}
                  {endEvent &&
                  <div css={moreInfoContainerStyle}>
                    <span css={moreInfoHeaderStyle(theme)}>End Timestamp</span>
                    <span css={moreInfoDetailStyle}>
              {moment(endEvent.getIn(["payload", "timestamp"])).format("lll")}
            </span>
                  </div>}
                </div>;
          } else if (!allowedToFlashForUser) {
            const message = "The owner was not in the required Group for the Gamification channel.";
            content =
                <div css={topContainerStyle}>
                  <div css={moreInfoContainerStyle}>
                    <span css={moreInfoHeaderStyle(theme)}>Owner</span>
                    <span css={moreInfoDetailStyle}>{ownerName}</span>
                  </div>
                  <div css={warningContainerStyle}>
                    <FontAwesomeIcon icon={faExclamationCircle} color={"orange"} />
                    <span>&nbsp;{message}</span>
                  </div>
                </div>;
          } else if (!startEvent && allowedToFlashForUser && !dataLoadedEvent) {
            const message = "Gamification failed to load the data for this placement.";
            content =
                <div css={topContainerStyle}>
                  <div css={moreInfoContainerStyle}>
                    <span css={moreInfoHeaderStyle(theme)}>Owner</span>
                    <span css={moreInfoDetailStyle}>{ownerName}</span>
                  </div>
                  <div css={warningContainerStyle}>
                    <FontAwesomeIcon icon={faExclamationTriangle} color={"red"} />
                    <span>&nbsp;{message}</span>
                  </div>
                </div>;
          }
          return (
              <div key={flashId}>
                <hr />
                {content}
              </div>);
        }
      });

  return <div css={css({marginTop: "2rem"})}>{flashDetails}</div>;
});

const dealFlashMatchesSearchTerm = (dealFlash, searchTerm) => {
  const sentEvents = dealFlash
      .get("deviceIdAndCategoryToEvents")
      .find(([deviceId, _]) => deviceId === undefined)[1]
      .get("deal_flash:sent");
  const placement = dealFlash.get("placement");
  const crmId = placement.get("originalCrmId");
  const candidateName = placement.get("candidateName");
  const jobTitle = placement.get("jobTitle");

  // TODO remove code for payload without users after suitable transition
  const ownerNames = sentEvents
      .flatMap(sentEvent => sentEvent.hasIn(["payload", "users"]) ?
          sentEvent.getIn(["payload", "users"]).map(user => user.get("userId")) :
          Immutable.List.of(sentEvent.getIn(["payload", "userId"])))
      .map(userId => Users.getUser(userId))
      .filter(user => user)
      .map(user => user.get("fullName"))
      .reduce((total, name) => total + name);

  return (crmId + candidateName + jobTitle + ownerNames).toLowerCase().includes(searchTerm.toLowerCase());
};

const generateDealFlashRow = (dealFlash, cube19PlacementIdToIsReflashing, onShowMoreInfo, onReflashPressed) => {
  const placement = dealFlash.get("placement");
  const placementId = dealFlash.get("placementId");
  const sentEvents = dealFlash
      .get("deviceIdAndCategoryToEvents")
      .find(([deviceId, _]) => deviceId === undefined)[1]
      .get("deal_flash:sent");
  const isReflash = sentEvents.first().getIn(["payload", "isReflash"]);
  const isExtension = placement.get("isExtension");
  const dateSent = moment(sentEvents.first().get("timestamp")).format("lll");
  // TODO remove code for payload without users after suitable transition
  const userIds = sentEvents.flatMap(sentEvent => sentEvent.hasIn(["payload", "users"]) ?
      sentEvent.getIn(["payload", "users"]).map(user => user.get("userId")) :
      Immutable.List.of(sentEvent.getIn(["payload", "userId"])));
  const ownerNames = userIds.map(userId => Users.getUser(userId))
      .filter(user => user)
      .map(user => user.get("fullName"))
      .reduce((total, name) => total + "/" + name);
  const pillStyle = css({
    display: "inline-block",
    backgroundColor: Colors.tvColor,
    borderRadius: 4,
    padding: "0.2rem",
    marginRight: "0.5rem",
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    color: "white"
  });
  return [
    <span>{placement.get("originalCrmId")}</span>,
    <span>{dateSent}</span>,
    <span>{ownerNames}</span>,
    <span>{placement.get("candidateName")}</span>,
    <span>{placement.get("jobTitle")}</span>,
    <div>
      {isExtension && <span css={pillStyle}>Extension</span>}
      {isReflash && <span css={pillStyle} className="TESTCAFE-reflash-indicator">Reflash</span>}
    </div>,
    <div>
      <IconButton
          label="More Info"
          icon="info"
          container="column"
          onClick={() => onShowMoreInfo(dealFlash)}
          size="large" />
      <IconButton
          label="Reflash"
          icon="redo"
          container="column"
          disableClick={cube19PlacementIdToIsReflashing.get(placementId)}
          onClick={() => onReflashPressed(placementId)}
          size="large" />
    </div>
  ];
};

const loadPlacementNames = placementIds => Ajax
    .put({url: "dealflash/placement-names", json: placementIds.toArray()})
    .then(res => Immutable.fromJS(res));

const loadFlashHistory = () => Ajax.get({url: "dealflash/history"}).then(res => Immutable.fromJS(res));

const reflashPlacement = cube19PlacementId => Ajax
    .put({url: "dealflash/for-placement/" + cube19PlacementId})
    .then(res => Immutable.fromJS(res));

const moreInfoContainerStyle = css({margin: "1rem"});
const moreInfoHeaderStyle = theme => css({
  fontFamily: theme.typography.fontFamilyBold,
  display: "block",
  marginBottom: "1rem",
  whiteSpace: "nowrap"
});
const moreInfoDetailStyle = css({whiteSpace: "nowrap"});

export default (props) => {
  const {theme} = React.useContext(CustomThemeContext);
  return <DealFlashPage theme={theme} {...props} />;
};