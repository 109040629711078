/** @jsxImportSource @emotion/react */

import React from "react";
import createReactClass from "create-react-class";
import GetContainerDimensions from "react-dimensions";
import PureRenderMixin from "react-addons-pure-render-mixin";
import {RadioGroup as RadioButtonGroup} from "@mui/material";
import {jsx, css} from "@emotion/react";

import AdminHeader from "js/admin/common/admin-header";
import LoadingSpinner from "js/common/views/loading-spinner";
import ErrorMsg from "js/common/views/error";
import SuccessMsg from "js/common/views/success";
import KpiPicker from "js/common/views/kpis/kpi-picker";
import DatePicker from "js/common/views/inputs/timeframe-picker/react-datepicker";
import CurrencyPicker from "js/admin/groups/currency-picker";
import SimpleTextInput from "js/common/views/inputs/simple-text-input";
import Checkbox from "js/common/views/inputs/checkbox";
import {TextButton} from "js/common/views/inputs/buttons";
import * as Users from "js/common/users";
import pure from "js/common/views/pure";
import currentClient from "js/common/repo/backbone/current-client";
import RadioButton from "js/common/views/inputs/radio-button";
import {CustomThemeContext} from "js/common/themes/CustomThemeProvider";

const fieldsetStyle = {
    borderColor: "#ccc",
    borderRadius: 5,
    padding: "1rem",
    margin: "0.5rem"
};

const legendStyle = theme =>({
    fontFamily: theme.typography.fontFamily,
    fontSize: "1.125rem",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    letterSpacing: 1
});

const spacing = {
    marginLeft: "0.5rem",
    marginRight: "0.5rem"
};

const radioButtonStyle = css`width: 170px`;

const radioButtonGroupCss = css`flex-direction: row !important;`;

const valueInputStyle = {
    width: 180,
    display: "inline-block"
};

const valueInputErrorStyle = theme =>({
    ...valueInputStyle,
    border: "1px solid #f00",
    backgroundColor: theme.themeId === "dark" ? "#502f2f" : "white"
});

const CreateTarget = GetContainerDimensions()(createReactClass({

    mixins: [ PureRenderMixin ],

    render() {
        const {
            kpis,
            target,
            isInitialState,
            onKpiIdChange,
            onPriorityChange,
            onPeriodChange,
            onStartDateChange,
            onValueChange,
            onCurrencyChange,
            onQualifierTypeChange,

            onCancelChangesRequest,
            onCreateTargetRequest,
            isCreatingNewTarget,
            errorsByProp,
            success,
            onSuccessMessageTimeout,
            containerWidth,
            theme,
            hasRequiredFields
        } = this.props;

        const isSmallScreen = containerWidth < 960;
        const containerStyle = {
            display: isSmallScreen ? "block" : "flex",
            justifyContent: "space-around",
            margin: "1rem 0.5rem"
        };
        const setTargetBtnLabel = isCreatingNewTarget ?
            <span><i className="fa fa-spinner fa-pulse fa-1x" />&nbsp;Setting...</span> :
            "Set Target";
        const targetValueLabelByPeriod = {
            WEEKLY: "per week",
            MONTHLY: "per month"
        };
        const targetQualifierType = target.get("qualifierType");
        const isFinancialTarget = target.get("valueType") === "monetary";
        const targetValueInputStyle = !errorsByProp.get("value") ? valueInputStyle : valueInputErrorStyle(theme);
        const createTargetFailedMsg = errorsByProp.get("create-target-failed");
        const currentUser = Users.getCurrentUser();
        const currentUserCanOnlySeeSelf = currentUser.get("dataVisibility") === "SELF";
        const groupTreeOrUserListContainerStyle = {
            borderTop: currentUserCanOnlySeeSelf ? 0 : "2px solid #272731",
            paddingTop: currentUserCanOnlySeeSelf ? 0 : "0.5rem",
            marginTop: 0,
            height: currentUserCanOnlySeeSelf ? 321 : 286,
            overflow: "auto"
        };
        const restrictedKeys = new Set(['e', '-', '+']);
        return (
            <div>
                <AdminHeader>
                    Add New Target
                </AdminHeader>
                <div style={containerStyle}>
                    <div style={{ width: isSmallScreen ? "auto" : "100%" }}>
                        <fieldset style={fieldsetStyle}>
                            <legend style={legendStyle(theme)}>
                                Metric
                            </legend>
                            <div style={{ maxWidth: 450 }}>
                                <KpiPicker
                                    kpis={kpis}
                                    multiSelect={false}
                                    clearable={false}
                                    placeholder="Select a Metric"
                                    selectedKpiId={target.get("kpiId")}
                                    onChange={onKpiIdChange} />
                            </div>
                            {errorsByProp.get("kpiId") &&
                                <ErrorMsg text={errorsByProp.get("kpiId")} style={{ marginBottom: 0 }} />}
                            <div style={{ marginTop: "0.5rem" }}>
                                <Checkbox
                                    iconStyle={{paddingTop: 0, paddingBottom: 0}}
                                    label="Make this target a priority"
                                    checked={target.get("priority")}
                                    onCheck={(e, isPriority) => onPriorityChange(isPriority)} />
                            </div>
                        </fieldset>
                        <fieldset style={fieldsetStyle}>
                            <legend style={legendStyle(theme)}>
                                Target Period
                            </legend>
                            <div>
                                {currentClient.hasPermission("USES_445_CALENDAR") ?
                                    <div style={{ fontFamily: theme.typography.fontFamilyBold }}>
                                        Weekly Target
                                    </div>
                                    :
                                    <RadioButtonGroup
                                        name="target-period-type"
                                        value={target.get("period")}
                                        style={{marginTop: -9}}
                                        onChange={(e, selectedPeriod) => onPeriodChange(selectedPeriod)}
                                        css={radioButtonGroupCss}>
                                        <RadioButton
                                            value={"WEEKLY"}
                                            label={"Weekly Target"}
                                            style={{width: "170px"}}
                                            css={radioButtonStyle} />
                                        <RadioButton
                                            value={"MONTHLY"}
                                            label={"Monthly Target"}
                                            style={{width: "170px"}}
                                            css={radioButtonStyle} />
                                    </RadioButtonGroup>}
                            </div>
                            <div style={{ paddingTop: "8px" }} data-test-id="new-target-date-picker">
                                <label>Starts from</label>
                                <div style={{ width: 180 }}>
                                    <DatePicker
                                        hideError={true}
                                        value={target.get("targetStart")}
                                        onDateChange={startDate => onStartDateChange(startDate)} />
                                </div>
                                {errorsByProp.get("targetStart") &&
                                    <ErrorMsg text={errorsByProp.get("targetStart")} style={{ marginBottom: 0 }} />}
                            </div>
                        </fieldset>
                        <fieldset style={fieldsetStyle}>
                            <legend style={legendStyle(theme)}>
                                Target Value
                            </legend>
                            <div>
                                <div style={{display: "inline-flex"}}>
                                    <SimpleTextInput
                                        data-test-id="new-target-value"
                                        type="number"
                                        placeholder="1138 or 1234.25"
                                        value={target.get("value")}
                                        onChange={value => onValueChange(value)}
                                        onKeyDown={e => {
                                            if(e.key && restrictedKeys.has(e.key.toLowerCase()))
                                                e.preventDefault();
                                        }}
                                        allowPaste={false}
                                        customStyle={{ ...targetValueInputStyle, marginRight: "0.5rem" }} />
                                    {isFinancialTarget &&
                                        <div style={{
                                            display: "inline-block",
                                            width: 110,
                                            verticalAlign: "bottom",
                                            marginRight: "0.5rem"
                                        }}
                                             data-test-id="currency-picker">
                                            <CurrencyPicker
                                                value={target.get("currency")}
                                                onChange={currency => onCurrencyChange(currency)} />
                                        </div>}
                                    <span style={{ fontSize: "0.875rem", margin: "auto" }}>
                                        {targetValueLabelByPeriod[target.get("period")]}
                                    </span>
                                </div>
                                {errorsByProp.get("value") &&
                                    <ErrorMsg text={errorsByProp.get("value")} style={{ marginBottom: 0 }} />}
                            </div>
                        </fieldset>
                    </div>
                    <div style={{ width: isSmallScreen ? "auto" : "100%" }}>
                        <fieldset style={fieldsetStyle}>
                            <legend style={legendStyle(theme)}>
                                {currentUserCanOnlySeeSelf ? "User" : "Users/Groups"}
                            </legend>
                            {!currentUserCanOnlySeeSelf &&
                                <RadioButtonGroup
                                    name="target-qualifier-type"
                                    value={targetQualifierType}
                                    style={{marginTop: -9}}
                                    onChange={(e, selectedType) => onQualifierTypeChange(selectedType)}
                                    css={radioButtonGroupCss}>
                                    <RadioButton
                                        value="USER"
                                        label="User"
                                        css={radioButtonStyle}
                                        style={{width: "170px"}}
                                    />
                                    <RadioButton
                                        value="GROUP"
                                        label="Group"
                                        css={radioButtonStyle}
                                        style={{width: "170px"}}
                                    />
                                </RadioButtonGroup>}
                            {errorsByProp.get("qualifierIds") && <ErrorMsg text={errorsByProp.get("qualifierIds")}/>}
                            <div style={groupTreeOrUserListContainerStyle}>
                                {(!currentUserCanOnlySeeSelf && targetQualifierType === "GROUP") && this.renderGroupTree()}
                                {targetQualifierType === "USER" && this.renderUserList()}
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div style={{ marginLeft: "1rem", marginRight: "1rem" }}>
                    {success && <SuccessMsg text={success} onMessageTimeout={onSuccessMessageTimeout} />}
                    {createTargetFailedMsg && <ErrorMsg text={createTargetFailedMsg} />}
                </div>
                <div style={{ textAlign: "center", margin: "1rem 0.5rem" }}>
                    <TextButton
                        icon="history"
                        label="Cancel"
                        onClick={onCancelChangesRequest}
                        disabled={isInitialState || isCreatingNewTarget}
                        style={spacing} />
                    <TextButton
                        label={setTargetBtnLabel}
                        type="primary"
                        disabled={!hasRequiredFields || isInitialState || !errorsByProp.isEmpty() || isCreatingNewTarget}
                        onClick={onCreateTargetRequest}
                        style={spacing} />
                </div>
            </div>
        );
    },

    renderGroupTree() {
        const { target, groups, onQualifierIdsChange } = this.props;
        return (
            <CheckboxGroupTree
                groups={groups}
                selectedGroupIds={target.get("qualifierIds")}
                onSelectedGroupIdsChange={groupIds => onQualifierIdsChange(groupIds)} />
        );
    },

    renderUserList() {
        const {
            target,
            users,
            isLoadingUsers,
            onSelectUsersToggleValueChange,
            onQualifierIdsChange,
        } = this.props;
        return (
            <div>
                {isLoadingUsers ?
                    <LoadingSpinner /> :
                    <CheckboxUserList
                        users={users}
                        selectUsersToggleValue={target.get("selectUsersToggleValue")}
                        onSelectUsersToggleValueChange={onSelectUsersToggleValueChange}
                        selectedUserIds={target.get("qualifierIds")}
                        onSelectedUserIdsChange={userIds => onQualifierIdsChange(userIds)} />}
            </div>
        );
    }

}));

const CheckboxGroupTree = pure(({ groups, selectedGroupIds, onSelectedGroupIdsChange }) => {
    if (!groups) {
        return <div>No Groups set up</div>;
    }
    return (
        <div>
            <ul style={{ listStyle: "none", marginBottom: 0 }}>
                {groups
                    .filter(group => !group.get("deleted"))
                    .map(group => {
                    const groupId = group.get("id");
                    const groupName = group.get("name");
                    const pixelsPerChar = 16;
                    const checkboxStyle = {
                        width: groupName.length * pixelsPerChar,
                        minWidth: 110,
                        paddingBottom: "0.25rem"
                    };
                    return (
                        <li key={group.get("id")}>
                            <Checkbox
                                label={groupName}
                                checked={selectedGroupIds.includes(groupId)}
                                style={{ display: "block", height: "1.7rem"}}
                                onCheck={(e, isChecked) => {
                                    const newSelectedGroupIds = isChecked ?
                                        selectedGroupIds.add(groupId) : selectedGroupIds.delete(groupId);
                                    onSelectedGroupIdsChange(newSelectedGroupIds);
                                }}
                                />
                            {group.get("values") &&
                                <CheckboxGroupTree
                                    groups={group.get("values")}
                                    selectedGroupIds={selectedGroupIds}
                                    onSelectedGroupIdsChange={onSelectedGroupIdsChange} />}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
});

const CheckboxUserList = pure(({
    users,
    selectUsersToggleValue,
    onSelectUsersToggleValueChange,
    selectedUserIds,
    onSelectedUserIdsChange
}) => (<div>
        {users.count() > 1 &&
            <RadioButtonGroup
                name="select-all-users-toggle"
                value={selectUsersToggleValue ? selectUsersToggleValue : null}
                onChange={(e, value) => onSelectUsersToggleValueChange(value)}
                flexDirection={"row"}
                style={{marginTop: -5}}
                css={[radioButtonGroupCss, css({ marginTop: "0.25rem", marginBottom: "0.5rem" })]}>
                <RadioButton
                    value="ALL"
                    label="Select all"
                    css={radioButtonStyle}
                    style={{width: "170px"}}
                />
                <RadioButton
                    value="NONE"
                    label="Deselect all"
                    css={radioButtonStyle}
                    style={{width: "170px"}}
                />
            </RadioButtonGroup>}
            <div style={{marginTop: -15}}>
        {users
            .sortBy(user => user.get("fullName").toLowerCase())
            .map(user => (
                <Checkbox
                    key={user.get("id")}
                    label={user.get("fullName")}
                    checked={selectedUserIds.includes(user.get("id"))}
                    style={{display: "block", height: "1.86rem", overflow: "hidden"}}
                    onCheck={(e, isChecked) => {
                        const userId = user.get("id");
                        const newSelectedUserIds = isChecked ?
                            selectedUserIds.add(userId) : selectedUserIds.delete(userId);
                        onSelectedUserIdsChange(newSelectedUserIds);
                    }} />
            ))
        }
            </div>
    </div>
));

export default (props) => {
    const {theme} = React.useContext(CustomThemeContext);
    return <CreateTarget theme={theme} {...props} />;
};