import React from "react";
import createReactClass from "create-react-class";
import PureRenderMixin from "react-addons-pure-render-mixin";

import {nextDirection, directionToSortIcon} from "js/job-pipelines/sort-utils";

import { Chip } from '@mui/material';
import Checkbox from "js/common/views/inputs/checkbox";

export default createReactClass({

    mixins: [PureRenderMixin],

    render() {
        const {
        	idToColumn,
            multiSort,
            onToggleMultiSort,
            columnIdToSort,
            onChangeColumnIdToSort,
            stages
        } = this.props;
        return (
            <div style={{display: "inline-block"}}>
                <Checkbox
                    style={{display: "inline-block", verticalAlign: "middle", width: "11.5rem"}}
                    testId="sort-many-columns"
                    label="Sort Many Columns"
                    onCheck={onToggleMultiSort}
                    checked={multiSort} />
                {multiSort && <div style={{display: "inline-block"}}>
                    <div style={{display: "flex", flexWrap: "wrap"}}>
                        {columnIdToSort
                            .entrySeq()
                            .sortBy(([columnId, sort]) => sort.get("ordering"))
                            .map(([columnId, sort]) => {
                                const isSimpleColumn = idToColumn.has(columnId);
                                let label;
                                if (isSimpleColumn) {
                                    label = idToColumn.get(columnId).get("label");
                                } else {
                                    label = stages.find(stage => stage.get("id") === sort.get("stageId")).get("name");
                                }
                                return (
                                    <Chip
                                        key={columnId}
                                        style={{margin: "0.5rem"}}
                                        onClick={() => onChangeColumnIdToSort(columnIdToSort.updateIn([columnId, "direction"], nextDirection))}
                                        onDelete={() => onChangeColumnIdToSort(columnIdToSort.remove(columnId))}
                                        label={<span>{label} {directionToSortIcon(sort.get("direction"), {paddingLeft: 0})}</span>} />);
                            })
                            .toArray()
                        }
                    </div>
                </div>}
            </div>
        );
    }

});
