import React from "react";
import { getUserAgent } from "js/common/ua-parser";
import pure from "js/common/views/pure";
import Dialog from "js/common/views/tabs-dialog";
import {TextButton} from "js/common/views/inputs/buttons";
import {yellowText} from "js/common/cube19-colors";

export default pure(({
	onRequestClose,
	width
}) => {
    const headerStyle = {
        fontSize: "1rem",
        fontWeight: "bold",
        lineHeight: 1.6,
        marginBottom: "0.3rem"
    };
    const paragraphStyle = {
        marginBottom: "0.5rem"
    };
    const padding = {
        paddingTop: "0.25rem",
        paddingBottom: "0.25rem"
    };
    const browser = getUserAgent().getBrowser();
    const isChrome = browser.name === "Chrome";
    const isEdgeOrIe = browser.name === "Edge" || browser.name === "IE";
	return (
		<Dialog
            onRequestClose={onRequestClose}
            label={
                <span style={{ color: yellowText, paddingBottom: "0.5rem" }}>
                    <i className="fa fa-lightbulb-o" style={{ paddingRight: 8 }} />Scrolling Shortcuts
                </span>
            }
            content={
                <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                    <p style={paragraphStyle}>
                        It looks like this might be your first time in Jobs Pipeline on a Windows computer.
                    </p>
                    <p style={paragraphStyle}>
                        {`In case you didn't already know, here are some scrolling shortcuts you can use on your mouse or keyboard:`}
                    </p>
                    <div style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                        <h2 style={headerStyle}>
                            Horizontal Scrolling <i className="fa fa-arrows-h" style={{ paddingLeft: 8 }} />
                        </h2>
                        <ul style={{ marginLeft: "1.5rem" }}>
                            <li style={padding}>
                                hold down the middle scroll wheel while moving the mouse (this will work for vertical scrolling also), or
                            </li>
                            {(!isChrome || !isEdgeOrIe) &&
                                <li style={padding}>
                                    hold down the {isChrome ? <code>Shift</code> : <code>Ctrl and Shift</code>} key button while moving the mouse's middle scroll wheel, or
                                </li>}
                            <li style={padding}>
                                click on the pipeline you'd like to scroll on then press either the <code>Left or Right Arrow</code> key buttons
                            </li>
                        </ul>
                        <h2 style={headerStyle}>
                            Vertical Scrolling <i className="fa fa-arrows-v" style={{ paddingLeft: 8 }} />
                        </h2>
                        <ul style={{ marginLeft: "1.5rem" }}>
                            <li style={padding}>
                                {`use the mouse's middle scroll wheel, or`}
                            </li>
                            <li style={padding}>
                                press the <code>Spacebar</code> button to scroll down. To scroll back up, press both the <code>Shift</code> and <code>Spacebar</code> key buttons
                            </li>
                            <li style={padding}>
                                click on the pipeline you'd like to scroll on then press either the <code>Up or Down Arrow</code> key buttons
                            </li>
                        </ul>
                        <TextButton label="Got it!" onClick={onRequestClose} testId="ok-dialog-button"/>
                    </div>
                </div>
            }
            width={width > 1024 ? 945 : "100%"}
            height={window.innerHeight > 640 ? 510 : "100%"} />
	);
});
